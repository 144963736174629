<template>
  <div  v-loading="loading" element-loading-text="数据较多，拼命加载中..." class="systemServiceData-container common-padding">
    <breadcrumb :BreadcrumbData="BreadcrumbData"></breadcrumb>
    <div class="common-screen-container">
      <!-- <div v-if="!$vuex.state.onlyCompany" class="common-input-container">
        <span>所属公司:</span>
        <el-select
          class="common-screen-input"
          v-model="params.companyId"
          @change="companyFun"
          placeholder="请选择"
          filterable
        >
          <el-option
            v-for="item in rolesCompany"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div> -->
      <!-- <div class="common-input-container">
        <span>所属部门:</span>
        <el-cascader
          class="common-screen-input"
          v-model="params.departId"
          :options="selectDepart"
          @change="commonFun"
          :props="{
            label: 'name',
            children: 'child',
            value: 'id',
            emitPath: false,
          }"
        ></el-cascader>
      </div> -->
      <br />
      <!-- <div class="common-input-container">
        <el-date-picker
          v-model="params.datePicker"
          :clearable="false"
          @change="
            () => {
              changeDate();
            }
          "
          type="daterange"
          range-separator="~"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </div>
      <div class="common-input-container">
        <el-radio-group v-model="params.type" @change="changeRadio">
          <el-radio-button :label="0">今天</el-radio-button>
          <el-radio-button :label="1">近7天</el-radio-button>
          <el-radio-button :label="2">近30天</el-radio-button>
          <el-radio-button :label="3">近90天</el-radio-button>
        </el-radio-group>
      </div> -->
      <div class="common-input-container">
        <common-lately-date @commonGetDate="commonGetDate" :commonRadio="commonRadio" :startDate="params.startDate" :endDate="params.endDate" ref="commonReset"></common-lately-date>
      </div>
      <div class="common-input-container">
        <!-- <el-button class="common-screen-btn" type="primary" @click=" () => { systemServiceData()}">查 询</el-button> -->
        <el-button class="common-screen-btn" plain @click="() => resetBtn()">重 置</el-button>
      </div>
      
    </div>
    <div class="systemServiceData-content">
      <table class="systemServiceData-table">
        <tbody>
          <tr>
            <th>咨询人数</th>
            <th>接待人数</th>
            <th>首响时间(s)</th>
            <th>平响时间(s)</th>
            <th>问答比</th>
            <th>回复率</th>
            <th>三分钟回复率</th>
            <th>五分钟回复率</th>
          </tr>
          <tr>
            <td>{{ (serveData && serveData.consultationsNum) || "--" }}</td>
            <td>{{ (serveData && serveData.receptionNum) || "--" }}</td>
            <td>{{ (serveData && serveData.firstTime) || "--" }}</td>
            <td>{{ (serveData && serveData.avgTime) || "--" }}</td>
            <td>{{ (serveData && serveData.qaRate) ? serveData.qaRate + '%' : "--" }}</td>
            <td>{{ (serveData && serveData.responseRate) ? serveData.responseRate + '%' : "--" }}</td>
            <td>{{ (serveData && serveData.threeMinuteResponseRate) ? serveData.threeMinuteResponseRate + '%' : "--" }}</td>
            <td>{{ (serveData && serveData.fiveMinuteResponseRate) ? serveData.fiveMinuteResponseRate + '%' : "--" }}</td>
          </tr>
        </tbody>
      </table>
      <div id="echartsRadar"></div>
    </div>
    <el-table class="common-table" :data="tableData" @sort-change="tableSort" fit>
      <!-- <el-table-column prop="shopLogo" width="80">
        <template slot-scope="scope">
          <span>
            <img
              class="common-iconImg"
              v-if="scope.row.shopLogo"
              :src="scope.row.shopLogo"
            />
            <span v-if="!scope.row.shopLogo"
              ><i class="el-icon-user common-iconImg-MR"></i
            ></span>
          </span>
        </template>
      </el-table-column> -->
      <el-table-column
        prop="shopName"
        label="店铺名称"
        :formatter="tableColumn"
         width="200"
      >
      <template slot-scope="scope">
          <el-button
            v-if="scope.row.shopId"
            type="text"
            size="small"
            @click="
              () => {
                openShopDetails(scope.row)
              }"
            >{{scope.row.shopName}}</el-button
          >
          <!-- <el-button v-else type="text" style="color: #999" size="small"
            >查看详情</el-button
          > -->
        </template>
      </el-table-column>
      <el-table-column
        prop="platformName"
        label="所属平台"
        sortable="custom"
        :formatter="tableColumn"
         width="200"
      ></el-table-column>
      <el-table-column
        prop="consultationsNum"
        label="咨询人数"
        sortable="custom"
        :formatter="tableColumn"
         width="200"
      ></el-table-column>
      <el-table-column
        prop="receptionNum"
        label="接待人数"
        sortable="custom"
        :formatter="tableColumn"
         width="200"
      ></el-table-column>
      <el-table-column
        prop="firstTime"
        label="首响/目标均值"
        sortable="custom"
        :formatter="tableColumn"
         width="200"
      >
        <template slot-scope="scope">
          <span :class="[(scope.row.firstTime&&scope.row.firstTimeSE)? (Number(scope.row.firstTime)>Number(scope.row.firstTimeSE)? 'fontRed':'fontGreen'):'' ]">{{scope.row.firstTime || scope.row.firstTime == '0'? scope.row.firstTime+'s':'--'}}</span>
          <span>/{{scope.row.firstTimeSE || scope.row.firstTimeSE == '0'? scope.row.firstTimeSE + 's' : '--'}}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="avgTime"
        label="平响/目标均值"
        sortable="custom"
        :formatter="tableColumn"
         width="200"
      >
        <template slot-scope="scope">
          <span :class="[(scope.row.avgTime&&scope.row.avgTimeSE)? (Number(scope.row.avgTime)>Number(scope.row.avgTimeSE)? 'fontRed':'fontGreen'):'' ]">{{scope.row.avgTime||scope.row.avgTime == '0'? scope.row.avgTime+'s':'--'}}</span>
          <span>/{{scope.row.avgTimeSE||scope.row.avgTimeSE=='0'? scope.row.avgTimeSE + 's' : '--'}}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="qaRate"
        label="问答比/目标均值"
        sortable="custom"
         width="200"
      >
        <template slot-scope="scope">
          <span :class="[(scope.row.qaRate&&scope.row.questionAnswerRatioSE)? (Number(scope.row.qaRate)<Number(scope.row.questionAnswerRatioSE)? 'fontRed':'fontGreen'):'' ]">{{scope.row.qaRate||scope.row.qaRate=='0'? scope.row.qaRate+'%':'--'}}</span>
          <span>/{{scope.row.questionAnswerRatioSE||scope.row.questionAnswerRatioSE =='0'? scope.row.questionAnswerRatioSE + '%' : '--'}}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column
        prop="responseRate"
        label="回复率"
        sortable="custom"
         width="200"
      >
        <template slot-scope="scope">
          <span>
           {{scope.row.responseRate ? scope.row.responseRate + '%' : '--'}}
          </span>
        </template>
      </el-table-column> -->
      <el-table-column
        prop="threeMinuteResponseRate"
        label="三分钟回复率/目标均值"
        sortable="custom"
         width="200"
      >
        <template slot-scope="scope">
          <span :class="[(scope.row.threeMinuteResponseRate&&scope.row.thereTimeRatioSE)? (Number(scope.row.threeMinuteResponseRate)<Number(scope.row.thereTimeRatioSE)? 'fontRed':'fontGreen'):'' ]">{{scope.row.threeMinuteResponseRate||scope.row.threeMinuteResponseRate=='0'? scope.row.threeMinuteResponseRate+'%':'--'}}</span>
          <span>/{{scope.row.thereTimeRatioSE||scope.row.thereTimeRatioSE=='0'? scope.row.thereTimeRatioSE + '%' : '--'}}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="fiveMinuteResponseRate"
        label="五分钟回复率/目标均值"
        sortable="custom"
         width="200"
      >
        <template slot-scope="scope">
          <span :class="[(scope.row.fiveMinuteResponseRate&&scope.row.fiveTimeRatioSE)? (Number(scope.row.fiveMinuteResponseRate)<Number(scope.row.fiveTimeRatioSE)? 'fontRed':'fontGreen'):'' ]">{{scope.row.fiveMinuteResponseRate||scope.row.fiveMinuteResponseRate=='0'? scope.row.fiveMinuteResponseRate+'%':'--'}}</span>
          <span>/{{scope.row.fiveTimeRatioSE||scope.row.fiveTimeRatioSE=='0'? scope.row.fiveTimeRatioSE + '%' : '--'}}</span>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="(e) => pageFun(e, 'pageSize')"
      @current-change="(e) => pageFun(e)"
      style="text-align: center"
      :page-sizes="pagination.pageSizes"
      :page-size="params.pageSize"
      :current-page="params.page"
      layout="total, prev, pager, next,sizes, jumper"
      :total="pagination.total"
    >
    </el-pagination>
  </div>
</template>
<script>
import Breadcrumb from "../../components/common/breadcrumb.vue";
import {
  getLatelyDay,
  DateTransform,
  Config,
  tableColumn,
  toLine,
} from "../../utils/index.js";
import {
  systemServiceData,
  systemServiceList,
  uploadDataXslx,
} from "../../service/dataInfo.js";
import { commonDepartAll } from "../../service/common.js";
import CommonLatelyDate from "../../components/common/commonLatelyDate.vue"
import { mapState } from "vuex";

export default {
  components: { Breadcrumb, CommonLatelyDate },
  data() {
    return {
      DateTransform,
      tableColumn,
      btnLoading: false,
      loading: false,
      BreadcrumbData: [
        { title: "首页", isLink: true, url: "/index" },
        { title: "数据", isLink: false },
        { title: "系统店铺服务数据", isLink: false },
      ],
      pagination: {
        total: 0,
        pageSizes: [5, 10, 20, 50, 100],
      },
      commonRadio: null,
      params: {
        startDate: DateTransform(new Date()),
        endDate: DateTransform(new Date()),
        page: 1,
        pageSize: 10,
      },
      serveData: {}, // 店铺服务数据
      selectDepart: [], // 部门下拉
      rolesCompany: this.$sto.get(Config.constants.userInfo).roles_company, // 所属公司下拉
      tableData: [],
    };
  },
  mounted() {
    this.echartsRadar();
  },
  created() {
    this.getComId()
    
    // this.systemServiceData();
  },
  computed: {
    ...mapState(["getUserInfo","comId"])
  },
  methods: {
    // 获取comID&&请求列表
    getComId() {
      setTimeout(()=>{
        if(this.comId){
          this.params.companyId = this.comId
        }else{
          this.params.companyId = this.getUserInfo.roles_company.filter((item) => { return item.id != 0; })[0].id
        }
        this.commonDepartAll(this.params.companyId);
        this.systemServiceData();
      },400)
    },
    commonGetDate (startTime, endTime, commonRadio) {
      this.params.page = 1 
      this.params.pageSize = 10
      this.params.startDate = startTime
      this.params.endDate = endTime
      this.commonRadio = commonRadio
      this.systemServiceData()
    },
    commonFun() {
      this.params.page = 1;
      this.params.pageSize = 10;
      this.systemServiceData();
    },
    downloadTemplate() {
      // 下载模板-暂时写死
      window.location.href =
        "https://sy-my.oss-cn-zhangjiakou.aliyuncs.com/sy/file/%E6%91%A9%E4%BA%BF%E6%9C%8D%E5%8A%A1%E6%95%B0%E6%8D%AE%E6%A8%A1%E6%9D%BF.xlsx";
    },
    async uploadDataXslx(e) {
      // 上传员工表格
      this.btnLoading = true;
      let file = e.target.files[0];
      let format = file.name.split(".")[file.name.split(".").length - 1];
      if (format != "xls" && format != "xlsx") {
        this.$message.error("仅支持xls、xlsx格式文件");
        e.target.value = "";
        this.btnLoading = false;
        return;
      }
      await uploadDataXslx({ file: file });
      this.btnLoading = false;
      e.target.value = "";
      this.$message.success("上传成功");
      this.systemServiceData();
    },
    download() {
      let params = { ...this.params };
      delete params.page;
      delete params.pageSize;
      let departIdType = typeof params.departId;
      if (departIdType == "object" || departIdType == "undefined") {
        delete params.departId;
      }
      let url = Config.api.url + "/dataRecord/serveData/excel/detailExport";
      let paramArr = [];
      for (let item in params) {
        if (params[item]) {
          paramArr.push(item + "=" + params[item]);
        }
      }
      url = url + "?" + paramArr.join("&");
      window.location.href = url;
    },
    pageFun(e, type) {
      // 分页
      if (type == "pageSize") {
        this.params.pageSize = e;
      } else {
        this.params.page = e;
      }
      this.systemServiceData();
    },
    async systemServiceList() {
      // 获取表格数据
      let params = { ...this.params };
      let departIdType = typeof params.departId;
      if (departIdType == "object" || departIdType == "undefined") {
        delete params.departId;
      }
      if (!params.sortType) {
        delete params.sortField;
        delete params.sortType;
      }
      let resData = (await systemServiceList(params)).data;
      this.pagination.total = resData.total;
      this.pagination.pageSize = resData.size;
      this.tableData = resData.list;
    },
    resetBtn() {
      this.commonRadio = 3
      this.params = {
        startDate: DateTransform(getLatelyDay(90)[0]),
        endDate: DateTransform(getLatelyDay(90)[1]),
        pageNum: 1,
        pageSize: 10,
        companyId: this.comId
      };
      this.commonDepartAll(this.comId);
      this.$refs.commonReset.resetFun(3, DateTransform(getLatelyDay(90)[0]), DateTransform(getLatelyDay(90)[1]))
      this.systemServiceData();
    },
    companyFun(id) {
      //选择公司筛选
      this.params.departId = [];
      this.systemServiceData();
      this.commonDepartAll(id);
    },
    async commonDepartAll(id) {
      // 部门 下拉
      let resData = (await commonDepartAll({}, id)).data;
      this.selectDepart = resData ? resData : [];
    },
    changeDate() {
      // 选择时间时清空近几天类型选择
      this.params.type = null;
      this.systemServiceData();
    },
    async systemServiceData() {
      // 索取服务数据
      let params = { ...this.params };
      this.loading = true;
      let departIdType = typeof params.departId;
      if (departIdType == "object" || departIdType == "undefined") {
        delete params.departId;
      }
      if (!params.sortType) {
        delete params.sortField;
        delete params.sortType;
      }
      delete params.page;
      delete params.pageSize;
      let resData = (await systemServiceData(params)).data;
      this.systemServiceList();
      this.serveData = resData;
      this.loading = false;
    },
    tableSort(e) {
      let params = { ...this.params };
      params.sortField = toLine(e.prop);
      switch (e.order) {
        case "ascending":
          params.sortType = 1;
          break;
        case "descending":
          params.sortType = 2;
          break;
        default:
          params.sortType = null;
      }
      this.params = params;
      this.systemServiceList();
    },
    echartsRadar() {
      // 雷达图
      let myChart = this.$echarts.init(document.getElementById("echartsRadar"));

      const buildSeries = function (data) {
        // 设置雷达图样式
        const helper = data.map((item, index) => {
          const arr = new Array(data.length);
          arr.splice(index, 1, item);
          return arr;
        });
        return [data, ...helper].map((item, index) => {
          return {
            type: "radar",
            symbol: index === 0 ? "circle" : "none",
            symbolSize: 6,
            itemStyle: {
              normal: {
                borderWidth: 1,
                borderColor: "#fff",
                color: "#1890ff",
              },
            },
            lineStyle: {
              color: index === 0 ? "#1890ff" : "transparent",
            },
            areaStyle: {
              color: index === 0 ? "#1890ff" : "transparent",
              opacity: 0.6,
            },
            label: {
              color: "#1890ff",
              normal: {
                show: true,
                formatter: (params) => {
                  return params.value ? params.value + "%" : "";
                },
                color: "#1890ff",
              },
            },
            tooltip: {
              show: index === 0 ? false : true,
              formatter: function () {
                return (
                  source.indicator[index - 1].name + "：" + source.data[index - 1] + "%"
                );
              },
            },
            z: index === 0 ? 1 : 2,
            data: [item],
          };
        });
      };
      const source = {
        data: [90, 80, 70, 60, 95, 70],
        indicator: [
          { name: "最大同时接待(%)", max: 100 },
          { name: "回复率(%)", max: 100 },
          { name: "平响时间(%)", max: 100 },
          { name: "主动性(%)", max: 100 },
          { name: "问答比(%)", max: 100 },
        ],
      };
      let option = {
        tooltip: {},
        radar: {
          shape: "circle",
          name: {
            textStyle: {
              color: "#000000A6",
              // backgroundColor: "#999",
              borderRadius: 3,
              padding: [3, 5],
            },
          },
          indicator: source.indicator,
        },
        series: buildSeries(source.data),
      };
      myChart.setOption(option);
    },
    changeRadio(e) {
      let date;
      switch (e) {
        case 0:
          date = 1;
          break;
        case 1:
          date = 7;
          break;
        case 2:
          date = 30;
          break;
        case 3:
          date = 90;
          break;
      }
      this.params.datePicker = getLatelyDay(date);
      this.systemServiceData();
    },
    openShopDetails(row){
      window.open(`/customer/company-shop-list?shopId=${row.shopId}&shopName=${row.shopName}&from=systemServiceData&startDate=${this.params.startDate}&endDate=${this.params.endDate}&active=3&commonRadio=${this.commonRadio}`)
    }
  },
};
</script>
<style lang="less" scoped>
.systemServiceData-container {
  text-align: left;
  .iconImg {
    width: 40px;
  }
  .systemServiceData-content {
    margin: 48px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    #echartsRadar {
      flex: 1;
      height: 300px;
      display: none;
    }
    .systemServiceData-table {
      display: table;
      width: 100%;
      border-collapse: collapse;
      td,
      th {
        text-align: center;
        border: 1px solid #c9c9c9;
        color: rgba(0, 0, 0, 0.65);
        font-size: 14px;
      }
      th {
        background: #f5f5f5;
        height: 60px;
        line-height: 60px;
        font-weight: 600;
      }
      td {
        height: 85px;
        line-height: 85px;
      }
    }
  }
      .fontRed{
  color: #F24433;
}
.fontGreen{
  color: #56C51E;
} 
}
</style>
