import fetch from "./xhr/index";

/**
 * 上传KPI服务数据
 * @param
 */
export const uploadKPIXslx = param => fetch("upload", "/excel/upload", param);

/**
 * 上传店铺服务数据
 * @param
 */
export const uploadDataXslx = param => fetch("upload", "/dataRecord/serveData/excel/upload", param);

/**
 * 店铺服务数据分析-撤销上传当日数据
 * @param
 */
export const revokeUpload = (param) => fetch("GET", `/excel/revoke`, param);

/**
 * 系统服务数据-总报表
 * @param
 */
export const systemServiceData = (param) => fetch("GET", `/dataRecord/serveData/system/serviceData`, param);

/**
 * 系统服务数据店铺列表
 * @param
 */
export const systemServiceList = (param) => fetch("GET", `/dataRecord/serveData/system/serviceDataList`, param);

/**
 * 系统服务数据店铺详情
 * @param
 */
export const systemServeDetail = (param) => fetch("GET", `/dataRecord/serveData/system/systemServiceDataDetail`, param);

/**
 * 系统基准值 --- 设置基准值
 * @param
 */
export const setReference = (param) => fetch("POST", `/excel/setReference`, param);

/**
 * 系统基准值 --- 获取详情
 * @param
 */
export const getReference = (param) => fetch("GET", `/excel/getReference`, param);

/**
 * 客服详情 --- 数据汇总
 * @param
 */
export const serviceKPIDataTable = (param) => fetch("GET", `/excel/getServiceReport`, param);

/**
 * 满意度分析 -- 数据源dialog公司筛选下拉
 * @param
 */
export const shopList = param => fetch("GET", "/excel/getShopList", param);

/**
 * 满意度分析 -- 数据源dialog
 * @param
 */
export const satisfiedDialog = param => fetch("GET", "/excel/satisfactionDataDetail", param);

/**
 * 客服kpl -- 客服KPI详情
 * @param
 */
export const serviceKPIDetail = param => fetch("GET", "/excel/serviceKPIDetail", param);

/**
 * 客服kpl -- 添加备注
 * @param
 */
export const addRemark = param => fetch("GET", "/excel/addRemark", param);

/**
 * 客服kpi详情图表
 * @param
 */
export const serviceKPICharts = param => fetch("GET", "/excel/serviceKPIGroupDate", param);

/**
 * 满意度分析 -- 图表 
 * @param
 */
export const satisCharts = param => fetch("GET", "/excel/satisfactionAnalysisGroupDate", param);

/**
 * 服务分析 -- 店铺KPI 图表 
 * @param
 */
export const shopKPICharts = param => fetch("GET", "/excel/shopKPIGroupDate", param);

/**
 * 服务分析 -- 项目KPI 图表 
 * @param
 */
export const projectKPICharts = param => fetch("GET", "/excel/projectKPIGroupDate", param);

/**
 * 满意度分析列表
 * @param
 */
export const satisfactionSource = param => fetch("GET", "/excel/satisfactionData", param);

/**
 * 满意度分析列表
 * @param
 */
export const satisfactionList = param => fetch("GET", "/excel/satisfactionAnalysis", param);

/**
 * 获取客服KPI列表
 * @param
 */
export const serviceKPIList = param => fetch("GET", "/excel/serviceKPI", param);

/**
 * 获取店铺KPI列表
 * @param
 */
export const shopKPIList = param => fetch("GET", "/excel/shopKPI", param);

/**
 * 获取项目KPI列表
 * @param
 */
export const projectKPIList = param => fetch("GET", "/excel/projectKPI", param);

/**
 * 获取店铺服务数据
 * @param
 */
export const getServeData = param => fetch("GET", "/dataRecord/serveData/excel/record", param);

/**
 * 导出店铺服务数据
 * @param
 */
export const downServiceData = param => fetch("GET", "/dataRecord/serveData/excel/detailExport", param);

/**
 * 获取店铺服务数据列表
 * @param
 */
export const getServeDataList = param => fetch("GET", "/dataRecord/serveData/excel/shopRecord", param);

/**
 * 获取店铺服务数据详情列表
 * @params
 */
export const serveDataDetailList = param => fetch("GET", "/dataRecord/serveData/excel/detailList", param);

/**
 * 获取店铺交易数据
 * @params
 */
export const getTradeData = param => fetch("POST", "/dataRecord/dataCustomerTrade/allTradeData", param);

/**
 * 获取店铺交易数据
 * @params
 */
export const getTradeDataList = param => fetch("POST", "/dataRecord/dataCustomerTrade/tradeDataList", param);

/**
 * 上传店铺交易数据
 * @params
 */
export const uploadTradeXslx = param => fetch("upload", "/dataRecord/dataCustomerTrade/excel/upload", param);

/**
 * 获取交易数据详情列表
 * @params
 */
export const tradeDetailList = param => fetch("POST", "/dataRecord/dataCustomerTrade/tradeDataListById", param);

/**
 * 获取考勤统计列表
 * @params
 */
export const attendanceList = param => fetch("POST", "/cms/cmsRecordTime/list", param);

/**
 * 查看指定月份的考勤列表
 * @params
 */
export const monthList = param => fetch("POST", "/cms/cmsRecordDay/queryCurrent", param);

/**
 * 查看指定日期的考勤列表
 * @params
 */
export const dateList = param => fetch("POST", "/cms/cmsRecordDay/queryCurrent", param);

// /**
//  * 获取指定用户信息
//  * @param
//  */
// export const getUserInfo = (param, routeParam) => fetch("GET", `/admin/userinfo/${routeParam}`, param);
/**
 * 导出店铺服务数据
 * @param
 */
export const wxWarn = param => fetch("GET", "/cms/cmsShopAssessment/isAssessment", param);

/**
 * 查询公司是否考核子账号均值
 * @param
 */
export const readwxWarn = param => fetch("GET", "/cms/cmsShopAssessment/readAssessment", param);

/**
 * 微信机器人配置列表
 * @param
 */
export const wechatList = param => fetch("POST", "/ums/umsGid/findByGid", param);

/**
 * 微信机器人配置-设置
 * @param
 */
export const setWechat = param => fetch("POST", "/ums/umsGid/update", param);

